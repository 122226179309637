import React, { useState } from 'react';
import { VFC } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, IconButton, InputAdornment, Link, Stack, Typography } from '@mui/material';
import { observable_api_store } from 'api';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { error_store } from 'stores/error';

import { Layout } from '../components/AuthLayout';

import { StyledOutlinedInput } from 'theme/global.styled';
type State = {
  password: string;
  showPassword: boolean;
};

export const LoginPage: VFC = () => {
  const navigate = useNavigate();
  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
  });
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [state, setState] = useState('');

  const login = async () => {
    setState('loading');

    const res = await observable_api_store.api.authorization
      .authorizationTokenCreate({ username, password })
      .catch((res) => {
        setState('error');
        error_store.setError(`${res.response.data.detail}`);
      });

    if (!res) {
      return;
    }

    observable_api_store.setToken(res.data);
    setState('');
    navigate('/');
  };

  return (
    <Layout back={{ to: '/auth', text: 'Главный экран' }}>
      <Typography sx={{ mb: 4 }} variant="h1">
        Авторизация
      </Typography>
      <Stack spacing={2} sx={{ alignSelf: 'stretch' }}>
        <StyledOutlinedInput
          placeholder="GREENWAY ID"
          value={username}
          onChange={(e) => setUsername(e.currentTarget.value)}
        />
        <StyledOutlinedInput
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                style={{ color: 'gray' }}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          id="outlined-adornment-password"
          placeholder="Пароль"
          type={values.showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <Link component={RouterLink} style={{ alignSelf: 'flex-end' }} to="/auth/forgot">
          Забыли свой пароль?
        </Link>
        <Button fullWidth disabled={state === 'loading'} size="large" variant="contained" onClick={login}>
          Войти
        </Button>
      </Stack>
    </Layout>
  );
};
