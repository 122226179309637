import { observable_api_store } from 'api';
import { action, computed, makeObservable } from 'mobx';
import { DefaultStore } from 'stores/default';

import { EventResponse, Step } from 'api/__generated__';

import { stepsStore } from './steps';

export const ITEMS_PER_PAGE = 1000;

type Event = EventResponse & {
  isRead?: boolean;
};

class NotificationsStore extends DefaultStore<Event[], typeof observable_api_store.api.event.eventList> {
  constructor(props: { fetchFn: typeof observable_api_store.api.event.eventList; initialValue: Event[] }) {
    super(props);

    makeObservable(this, {
      unreadNotifications: computed,
      readNotifications: computed,
      badgeCount: computed,
      newStep: computed,
      readAll: action,
    });
  }

  public get unreadNotifications() {
    return this.data?.length ? this.data.filter((event) => event.is_active) : [];
  }

  public get readNotifications() {
    return this.data?.length ? this.data.filter((event) => !event.is_active) : [];
  }

  public get badgeCount() {
    return this.data?.filter((e) => e.is_active && !e.isRead)?.length || 0;
  }

  public get newStep() {
    let step: Step | undefined;

    if (!this.data) {
      return step;
    }

    for (let i = 0; i < this.data?.length; i++) {
      const event = this.data[i];

      if (event.is_shown || event.type !== 'step') {
        continue;
      }

      step = stepsStore.data.find((s) => s.id === event.step && (s.type === 'rank' || s.type === 'achievement'));

      if (step) {
        break;
      }
    }

    return step;
  }

  public readAll = async () => {
    if (!this.badgeCount) {
      return;
    }

    try {
      await observable_api_store.api.bulkEventIsActive.bulkEventIsActiveUpdate();
      this.setData(this.data.map((e) => ({ ...e, isRead: true })));
    } catch (e) {
      console.error(e);
    }
  };

  public resetReadAll = () => {
    this.setData(this.data.map(({ isRead, ...e }) => ({ ...e, is_active: false })));
  };

  public showAll = async () => {
    try {
      await observable_api_store.api.bulkEventIsShown.bulkEventIsShownUpdate();
      this.setData(this.data.map((e) => ({ ...e, is_shown: true })));
    } catch (e) {
      console.error(e);
    }
  };
}

export const notificationsStore = new NotificationsStore({
  fetchFn: (...args) => observable_api_store.api.event.eventList(...args),
  initialValue: [],
});
