import { observable_api_store } from 'api';
import { DefaultStore } from 'stores/default';

import { StepConditionInfo } from 'api/__generated__';

class StepConditionStore extends DefaultStore<
  StepConditionInfo[],
  typeof observable_api_store.api.stepConditionInfo.stepConditionInfoList
> {}

export const stepConditionStore = new StepConditionStore({
  fetchFn: (...args) => observable_api_store.api.stepConditionInfo.stepConditionInfoList(...args),
  initialValue: [],
});

class OptionalStepConditionStore extends DefaultStore<
  StepConditionInfo[],
  typeof observable_api_store.api.optionalStepConditionInfo.optionalStepConditionInfoList
> {}

export const optionalStepConditionStore = new OptionalStepConditionStore({
  fetchFn: (...args) => observable_api_store.api.optionalStepConditionInfo.optionalStepConditionInfoList(...args),
  initialValue: [],
});
