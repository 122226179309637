import { VFC, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { notificationsStore } from 'stores/notifications';

import Cards from './components/Cards';
import { useStateContent } from 'hooks/useStateContent';

export const NotificationsPage: VFC = observer(() => {
  useEffect(() => {
    notificationsStore.readAll();

    return () => {
      notificationsStore.resetReadAll();
    };
  }, []);

  const content = useStateContent({
    state: notificationsStore.state,
    successContent: (
      <Box sx={{ p: 2.5, height: 1, overflow: 'auto' }}>
        <Typography sx={{ mb: 2 }} variant="h1">
          Уведомления
        </Typography>
        <Cards />
      </Box>
    ),
  });

  return content;
});
