import { ReactNode } from 'react';

import { Box, List, Typography } from '@mui/material';

import MapItem from './components/MapItem';

type Question = {
  title: string;
  text: string | ReactNode;
};

export const QUESTIONS: Question[] = [
  {
    title: 'Какие правила игры?',
    text: (
      <>
        <p>
          Регистрируйся, приглашай партнеров и собирай экипаж из партнеров первой линии. Основная задача — выполнять
          игровые задания и получать игровые бонусы. Выполни вместе с командой все задачи, собери все ачивки и первый
          достигни победы!
        </p>
      </>
    ),
  },
  {
    title: 'Какой путь нужно пройти?',
    text: <MapItem />,
  },
  {
    title: 'Какие задания бывают?',
    text: (
      <>
        <Typography variant="h3">В игре Starlight есть 2 вида заданий:</Typography>
        <List disablePadding>
          <li>
            <Typography fontWeight={500}>Профессиональные</Typography>
            <p>
              Количество регистраций, активаций партнеров первой линии, ЛО по PV, работа с экипажем (так мы называем
              всех зарегистрированных партнеров твоей первой линии), коммуникация с командой, мотивация новичков.
            </p>
          </li>
          <li>
            <Typography fontWeight={500}>Обучение</Typography>
            <p>
              Изучение материалов на портале, посещение профессиональных зумов, онлайн и офлайн мероприятий, прохождение
              тестов на платформе.
            </p>
          </li>
        </List>
      </>
    ),
  },
  {
    title: 'Какие игровые бонусы есть в игре?',
    text: (
      <>
        <p>Игровые бонусы делятся на 2 типа:</p>
        <Typography fontWeight={500} sx={{ mt: 1 }} variant="h3">
          Star coins (Звездные монеты)
        </Typography>
        <p>
          Игровые монеты, которые ты получаешь за выполнение профессиональных заданий. Они отражают насколько эффективно
          и успешно ты выполняешь задания. Накопи как можно больше, чтобы впоследствии потратить их на подарки. Каждое
          задание содержит информацию, сколько ты можешь заработать за его успешное выполнение.
        </p>
        <p>• За регистрацию можно получить +10St.</p>
        <p>• Прохождение Zвёздной формулы +20St.</p>
        <p>• За 5 членов экипажа +50St и далее +10St за каждого.</p>
        <p>• За S1</p>
        <Box
          component="ul"
          sx={{
            'pl': 2,
            'mt': -1,
            'mb': 1,
            '& li': {
              listStyle: 'none',
              m: 0,
              mb: 0.5,
            },
          }}
        >
          <li>- в первой ветке +60St,</li>
          <li>- во 2-й ветке +70St,</li>
          <li>- в 3-й ветке +80St,</li>
          <li>- в 4-й ветке +90St,</li>
          <li>- далее +100St за каждого следующего S1 в других ветках.</li>
        </Box>
        <p>• Прохождение Zвёздного марафона +40St.</p>
        <p>За L</p>
        <Box
          component="ul"
          sx={{
            'pl': 2,
            'mt': -1,
            'mb': 1,
            '& li': {
              listStyle: 'none',
              m: 0,
              mb: 0.5,
            },
          }}
        >
          <li>- в первой ветке +110St,</li>
          <li>- во 2-й ветке +120St,</li>
          <li>- в 3-й ветке +130St,</li>
          <li>- в 4-й ветке +140St,</li>
          <li>- далее +150St за каждого следующего L в других ветках.</li>
        </Box>
        <p>• Прохождение курса «Мастерская» принесет вам +50St.</p>
        <p>• Посещение звездной вечеринки добавит в вашу копилку +150St.</p>
        <p>• + 5% от ЛО всего экипажа в периоде, когда ты S1 и выше.</p>
        <p>• +10% от ЛО всего экипажа в периоде, когда ты L и выше.</p>
        <p>• +15% от ЛО всего экипажа в периоде, когда ты M и выше.</p>
        <Typography fontWeight={500} sx={{ mt: 4 }} variant="h3">
          Виртуальные медали (Ачивки)
        </Typography>
        <p>
          Можно получить за достижение или победу в различных активностях и профессиональных успехах — например, большой
          ЛО, количество активаций в первую линию, изменение статуса у членов твоего экипажа. В подсказке у каждой
          Ачивки указано, что необходимо сделать, чтобы получить ее.
        </p>
      </>
    ),
  },
  {
    title: 'Для чего мне команда?',
    text: (
      <>
        <p>
          Ты и твоя первая линия является Экипажем, то есть командой, и успех каждого из вас приведет к общей победе.
        </p>
        <p>
          Мы считаем результаты заданий, выполненных тобой и твоей первой линией. Твои личные достижения засчитываются
          наставнику, у которого ты находишься в команде. Некоторые задания выполняются только благодаря действиям
          твоего экипажа.
        </p>
        <p>
          Кроме того, в игре есть командные ачивки — за активное развитие команды капитан получает 5%, 10% и 15% от
          общей суммы команды за успешное выполнение бонусных заданий.
        </p>
      </>
    ),
  },
  {
    title: 'Что еще необходимо для успеха?',
    text: (
      <>
        <p>
          Не забывай, что для некоторых заданий необходим рост ЛО PV и повышение твоего ранга в Гринвее. Твоя команда
          всегда должна состоять не менее чем из 5 человек, каждый из которых выполнит по 50 PV за отчетный период,
          иначе вам не удастся покинуть космодром. Будь максимально активен, и удача будет в твоей команде!
        </p>
      </>
    ),
  },
  {
    title: 'Как попасть в рейтинг?',
    text: (
      <>
        <p>В нашей игре существует несколько видов рейтингов:</p>
        <Typography fontWeight={500} sx={{ mt: 2 }} variant="h3">
          Командный рейтинг Team Value
        </Typography>
        <p>Он оставлен в порядке возрастания суммарного командного PV экипажа. </p>
        <p>
          Чтобы попасть в рейтинг, необходимо помогать партнерам увеличивать личный PV и подписывать новых членов
          экипажа — тогда ваш суммарный объем вырастет и вы займете лидирующие позиции. Не забывайте, каждый отчетный
          период PV сбрасываются и у вас появляется шанс вновь завоевать пальму первенства.
        </p>
        <p>
          В рейтинге могут участвовать только игроки, которые собирают экипаж и имеют звание «Капитан» и выше.
          Участники, получившие статус Гринвея «М Мастер», в этом рейтинге не отображаются.
        </p>
        <Typography fontWeight={500} sx={{ mt: 4 }} variant="h3">
          Индивидуальный рейтинг по количеству Starcoins
        </Typography>
        <p>
          В этот рейтинг могут попасть игроки, имеющие звание капитан и выше. Участники, получившие статус Гринвея «М
          Мастер», в этом рейтинге не отображаются.
        </p>
        <p>
          Задача — заработать максимально возможное количество монет Starcoins. Как и за что можно заработать монеты
          подробно описано в вопросе «Какие игровые бонусы есть в игре?».{' '}
        </p>
        <Typography fontWeight={500} sx={{ mt: 4 }} variant="h3">
          Индивидуальный рейтинг Bestseller
        </Typography>
        <p>
          Этот рейтинг составлен на основе личного объема. Здесь можно соревноваться всем участникам, не получившим
          статус Гринвея «М Мастер».
        </p>
        <p>
          В этом рейтинге все зависит только от тебя — увеличивай личный объем и заслужи право быть в топе рейтинга
          «Лучших продавцов»!
        </p>
      </>
    ),
  },
];
