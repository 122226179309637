import { VFC, useEffect, useState } from 'react';

import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { observable_api_store } from 'api';
import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/auth';
import { notificationsStore } from 'stores/notifications';
import { promptStore } from 'stores/prompt';

import Step from './components/Step';

import { State } from 'common/loading';

import { mainStore } from '../Main/store';
import { optionalStepConditionStore, stepConditionStore } from './store';

const StepCondition: VFC = () => {
  const [selectedStepIds, setSelectedStepIds] = useState<number[]>([]);
  const [selectedOptionalStepIds, setSelectedOptionalStepIds] = useState<number[]>([]);

  useEffect(() => {
    stepConditionStore.getData();
    optionalStepConditionStore.getData();

    return () => {
      stepConditionStore.destroy();
      optionalStepConditionStore.destroy();
    };
  }, []);

  const needComfirmButton = !!(
    stepConditionStore.data?.filter((c) => c.step_condition.type === 'in_game')?.length ||
    optionalStepConditionStore.data?.filter((c) => c.step_condition.type === 'in_game')?.length
  );

  const confirm = async () => {
    try {
      const answer = await promptStore.createPrompt<'Y' | 'N'>({
        title: (
          <Typography
            sx={{
              '& a': {
                'textDecoration': 'none',
                'color': '#7BD5FD',
                '&:hover': { textDecoration: 'underline' },
              },
            }}
          >
            Вы подтверждаете, что выполнили задания?
          </Typography>
        ),
        buttons: [
          {
            value: 'N',
            children: 'Нет',
            color: 'secondary',
          },
          {
            value: 'Y',
            children: 'Да',
            color: 'primary',
          },
        ],
      });

      if (answer !== 'Y') {
        throw new Error('');
      }

      // await Promise.all([
      //   ...selectedStepIds.map((id) => observable_api_store.api.stepConditionInfo.stepConditionInfoUpdate(String(id), { is_valid: true })),
      //   ...selectedOptionalStepIds.map((id) =>
      //     observable_api_store.api.stepConditionInfo.stepConditionInfoUpdate(String(id), { is_valid: true }),
      //   ),
      // ]);

      for (const id of [...selectedStepIds, ...selectedOptionalStepIds]) {
        await observable_api_store.api.stepConditionInfo.stepConditionInfoUpdate(String(id), { is_valid: true });
      }

      setSelectedStepIds([]);
      setSelectedOptionalStepIds([]);

      await Promise.all([
        authStore.reloadData({ disableStateChange: true }),
        notificationsStore.reloadData({ disableStateChange: true }),
        stepConditionStore.reloadData({ disableStateChange: true }),
        optionalStepConditionStore.reloadData({ disableStateChange: true }),
        mainStore.reloadData({ disableStateChange: true }),
      ]);
    } catch (e) {
      console.log('');
    }
  };

  return (
    <Box>
      {stepConditionStore.state === State.Success &&
        (stepConditionStore.data.length ? (
          <>
            <Typography fontWeight={500} sx={{ mb: 1 }}>
              Задани{stepConditionStore.data.length > 1 ? 'я' : 'e'}
            </Typography>
            {stepConditionStore.data.map((c) => (
              <Step
                key={c.id}
                checked={selectedStepIds.includes(c.id as number)}
                step={c}
                onToggle={(checked) => {
                  if (checked) {
                    setSelectedStepIds([...selectedStepIds, c.id as number]);
                  } else {
                    const index = selectedStepIds.findIndex((id) => id === c.id);
                    const newIds = selectedStepIds.slice();

                    newIds.splice(index, 1);
                    setSelectedStepIds(newIds);
                  }
                }}
              />
            ))}
            {!!optionalStepConditionStore.data?.length && (
              <>
                <Typography fontWeight={500} sx={{ mt: 2, mb: 1 }}>
                  Дополнительное задани{optionalStepConditionStore.data.length > 1 ? 'я' : 'e'}
                </Typography>
                {optionalStepConditionStore.data.map((c) =>
                  c.step_condition.type === 'in_game' ? (
                    <FormControlLabel
                      key={c.id}
                      control={
                        <Checkbox
                          checked={selectedOptionalStepIds.includes(c.id as number)}
                          onChange={(_, checked) => {
                            if (checked) {
                              setSelectedOptionalStepIds([...selectedOptionalStepIds, c.id as number]);
                            } else {
                              const index = selectedOptionalStepIds.findIndex((id) => id === c.id);
                              const newIds = selectedOptionalStepIds.slice();

                              newIds.splice(index, 1);
                              setSelectedOptionalStepIds(newIds);
                            }
                          }}
                        />
                      }
                      label={<span dangerouslySetInnerHTML={{ __html: c.step_condition.description }} />}
                      sx={{
                        '& a': {
                          'textDecoration': 'none',
                          'color': '#7BD5FD',
                          '&:hover': { textDecoration: 'underline' },
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      component="p"
                      dangerouslySetInnerHTML={{ __html: c.step_condition.description }}
                      sx={{
                        'py': 0.5,
                        '& a': {
                          'textDecoration': 'none',
                          'color': '#7BD5FD',
                          '&:hover': { textDecoration: 'underline' },
                        },
                      }}
                    />
                  ),
                )}
              </>
            )}
            {needComfirmButton && !!(selectedStepIds.length || selectedOptionalStepIds.length) && (
              <Button fullWidth sx={{ mt: 2 }} onClick={confirm}>
                Далее
              </Button>
            )}
          </>
        ) : (
          <Typography sx={{ textAlign: 'center' }}>Поздравляем, Вы вышли космический уровень!</Typography>
        ))}
    </Box>
  );
};

export default observer(StepCondition);
