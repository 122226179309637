import { VFC } from 'react';

import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Layout } from './components/AuthLayout';

export const AuthPage: VFC = () => {
  return (
    <Layout>
      <Typography sx={{ mb: 4 }} variant="h1">
        Starlight
      </Typography>
      <Stack spacing={2} sx={{ alignSelf: 'stretch' }}>
        <Button fullWidth component={Link} size="large" to={{ pathname: '/auth/login' }} variant="contained">
          Авторизоваться
        </Button>
        <Button
          fullWidth
          component={Link}
          size="large"
          sx={{ color: 'white' }}
          to={{ pathname: '/auth/register' }}
          variant="outlined"
        >
          Зарегистрироваться
        </Button>
      </Stack>
    </Layout>
  );
};
