import { HTMLAttributes, VFC } from 'react';

import { Box, Icon } from '@mui/material';
import { SxProps } from '@mui/system';

import { teamColors } from 'common/teamColor';

import { ReactComponent as RocketIcon } from 'assets/icons/rocket.svg';

const UserAvatar: VFC<HTMLAttributes<HTMLDivElement> & { userId: number; sx?: SxProps }> = ({
  sx,
  userId,
  ...other
}) => {
  const backgroundColor = teamColors[userId % teamColors.length];

  return (
    <Box
      sx={{
        color: 'common.white',
        borderRadius: '50%',
        width: 40,
        height: 40,
        backgroundColor,
        p: 1,
        ...sx,
      }}
      {...other}
    >
      <Icon component={RocketIcon} sx={{ width: 1, height: 1 }} />
    </Box>
  );
};

export default UserAvatar;
