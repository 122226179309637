import { observable_api_store } from 'api';
import { DefaultStore } from 'stores/default';

import { User } from 'api/__generated__';

class TeamStore extends DefaultStore<User[], typeof observable_api_store.api.team.teamRead> {}

export const teamStore = new TeamStore({
  fetchFn: (...args) => observable_api_store.api.team.teamRead(...args),
  initialValue: [],
});
