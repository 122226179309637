import { ComponentType, SVGProps } from 'react';

import { AuthPage } from 'pages/auth';
import { ForgotPage } from 'pages/auth/forgot';
import { ResetPasswordPage } from 'pages/auth/forgot/reset_password';
import { LoginPage } from 'pages/auth/login';
import { RegisterPage } from 'pages/auth/register';
import FaqPage from 'pages/Faq';
import InstructionsPage from 'pages/Instructions';
import LoginPageDeprecated from 'pages/Login';
import MainPage from 'pages/Main';
import { NotificationsPage } from 'pages/Notifications';
import OAuthPage from 'pages/OAuth';
import RaitingPage from 'pages/Raiting';
import TeamPage from 'pages/Team';

import { ReactComponent as FaqIcon } from 'assets/icons/menu/faq.svg';
import { ReactComponent as InstructionsIcon } from 'assets/icons/menu/instructions.svg';
import { ReactComponent as NotificationsIcon } from 'assets/icons/menu/notifications.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/menu/profile.svg';
import { ReactComponent as RaitingIcon } from 'assets/icons/menu/raiting.svg';
import { ReactComponent as TeamIcon } from 'assets/icons/menu/team.svg';

type Route = {
  path: string;
  withAuth?: boolean;
  PageComponent: ComponentType<any>;
  menu?: {
    title: string;
    Icon: ComponentType<SVGProps<SVGSVGElement>>;
    topMenu?: boolean;
  };
};

export const ROUTES: Route[] = [
  {
    path: '/',
    withAuth: true,
    PageComponent: MainPage,
    menu: {
      title: 'Профиль',
      Icon: ProfileIcon,
      topMenu: true,
    },
  },
  {
    path: '/team',
    withAuth: true,
    PageComponent: TeamPage,
    menu: {
      title: 'Мой экипаж',
      Icon: TeamIcon,
      topMenu: true,
    },
  },
  {
    path: '/instructions',
    withAuth: true,
    PageComponent: InstructionsPage,
    menu: {
      title: 'Видеоинструкции',
      Icon: InstructionsIcon,
      topMenu: true,
    },
  },
  {
    path: '/notifications',
    withAuth: true,
    PageComponent: NotificationsPage,
    menu: {
      title: 'Уведомления',
      Icon: NotificationsIcon,
      topMenu: true,
    },
  },
  {
    path: '/raiting',
    withAuth: true,
    PageComponent: RaitingPage,
    menu: {
      title: 'Рейтинг',
      Icon: RaitingIcon,
      topMenu: true,
    },
  },
  {
    path: '/faq',
    withAuth: true,
    PageComponent: FaqPage,
    menu: {
      title: 'Вопросы и ответы',
      Icon: FaqIcon,
    },
  },
  {
    path: '/login',
    PageComponent: LoginPageDeprecated,
  },
  {
    path: '/oauth',
    PageComponent: OAuthPage,
  },
  {
    path: '/auth',
    PageComponent: AuthPage,
  },
  {
    path: '/auth/login',
    PageComponent: LoginPage,
  },
  {
    path: '/auth/register',
    PageComponent: RegisterPage,
  },
  {
    path: '/auth/forgot',
    PageComponent: ForgotPage,
  },
  {
    path: '/reset_password',
    PageComponent: ResetPasswordPage,
  },
];
