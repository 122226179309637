import { Step } from 'api/__generated__';

export const getLastRank = (stepsReversed: number[], rankSteps: Step[]): Step | null => {
  for (let i = 0; i < stepsReversed.length; i++) {
    const rankStep = rankSteps.find((step) => step.id === stepsReversed[i]);

    if (rankStep) {
      return rankStep;
    }
  }

  return null;
};
