import { useState } from 'react';

import { Link } from '@mui/material';
import Lightbox from 'react-image-lightbox';

import mapSrc from 'assets/map.svg';

const MapItem = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <p>
        Посмотреть полный маршрут, который нужно пройти в игре, можно <Link onClick={() => setIsOpen(true)}>здесь</Link>
      </p>
      {isOpen && <Lightbox mainSrc={mapSrc} onCloseRequest={() => setIsOpen(false)} />}
    </>
  );
};

export default MapItem;
