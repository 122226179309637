import { VFC } from 'react';

import { observer } from 'mobx-react-lite';
import { notificationsStore } from 'stores/notifications';

import NewStep from './components/NewStep';
import Profile from './components/Profile';

const Main: VFC = () => {
  return notificationsStore.newStep ? <NewStep /> : <Profile />;
};

export default observer(Main);
