import { VFC, useEffect, useRef, useState } from 'react';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import { StepConditionInfo } from 'api/__generated__';

import VideoPopup, { IframeProps } from 'components/VideoPopup';

const Step: VFC<{ step: StepConditionInfo; checked: boolean; onToggle: (checked: boolean) => void }> = ({
  step,
  checked,
  onToggle,
}) => {
  const ref = useRef(null);
  const [videoIframeProps, setVideoIframeProps] = useState<IframeProps | null>(null);

  useEffect(() => {
    let videoLink: HTMLElement | null = null;

    let videoLinkClickHandler: () => void;

    if (ref.current) {
      const rootElem = ref.current as HTMLElement;

      videoLink = rootElem.querySelector('[data-src]');

      if (videoLink) {
        const data = (videoLink as HTMLElement).dataset as IframeProps;

        videoLinkClickHandler = () => {
          setVideoIframeProps(data);
        };

        videoLink.addEventListener('click', videoLinkClickHandler);
      }
    }

    return () => {
      if (videoLink && videoLinkClickHandler) {
        videoLink.removeEventListener('click', videoLinkClickHandler);
      }
    };
  }, []);

  return (
    <>
      {step.step_condition.type === 'in_game' ? (
        <FormControlLabel
          ref={ref}
          control={<Checkbox checked={checked} onChange={(_, checked) => onToggle(checked)} />}
          label={<span dangerouslySetInnerHTML={{ __html: step.step_condition.description }} />}
          sx={{
            '& a': {
              'textDecoration': 'none',
              'color': '#7BD5FD',
              '&:hover': { textDecoration: 'underline' },
            },
          }}
        />
      ) : (
        <Typography
          ref={ref}
          component="p"
          dangerouslySetInnerHTML={{ __html: step.step_condition.description }}
          sx={{
            'py': 0.5,
            '& a': {
              'textDecoration': 'none',
              'color': '#7BD5FD',
              '&:hover': { textDecoration: 'underline' },
            },
          }}
        />
      )}
      <VideoPopup iframeProps={videoIframeProps} onClose={() => setVideoIframeProps(null)} />
    </>
  );
};

export default Step;
