import { observable_api_store } from 'api';
import { DefaultStore } from 'stores/default';

import { RatingResponse } from 'api/__generated__';

class BestsellerStore extends DefaultStore<
  RatingResponse | null,
  typeof observable_api_store.api.bestsellerRating.bestsellerRatingRead
> {}

export const bestsellerStore = new BestsellerStore({
  fetchFn: (...args) => observable_api_store.api.bestsellerRating.bestsellerRatingRead(...args),
  initialValue: null,
});
