import { VFC, useEffect, useState } from 'react';

import { Button, Stack, Typography } from '@mui/material';
import { observable_api_store } from 'api';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { error_store } from 'stores/error';

import { Layout } from 'pages/auth/components/AuthLayout';

import { StyledOutlinedInput } from 'theme/global.styled';

export const ForgotPage: VFC = observer(() => {
  useEffect(() => {
    recovery_observer.reset();
  }, []);

  const [values, setValues] = useState({ email: '', username: '' });

  const handleChange = (prop: 'email' | 'username') => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <Layout back={{ to: '/auth/login', text: 'Авторизация' }}>
      <Typography sx={{ mb: 4 }} variant="h1">
        Восстановить пароль
      </Typography>
      {recovery_observer.recover_by === 'email' ? (
        <Stack spacing={2} sx={{ alignSelf: 'stretch', marginBottom: '16px' }}>
          <span style={{ textAlign: 'center', color: 'rgba(255, 255, 255, 0.64)', marginBottom: '16px' }}>
            {recovery_observer.send_couner > 0 && !recovery_observer.loading ? (
              <>Сообщение с инструкциями отправлено на E-mail: {recovery_observer.email} </>
            ) : (
              <>
                Введите электронную почту, которая использовалась для создания учетной записи, и мы пришлем ссылку для
                сброса пароля
              </>
            )}
          </span>
          <StyledOutlinedInput
            autoComplete="registration section enter email"
            placeholder="E-mail"
            style={{
              ...(values.email ? { background: /^\S+@\S+\.\S+$/.test(values.email) ? '#C2E3FC' : '#ffdddd' } : {}),
              marginBottom: '16px',
            }}
            value={values.email}
            onChange={handleChange('email')}
          />
        </Stack>
      ) : recovery_observer.recover_by === 'username' ? (
        <Stack spacing={2} sx={{ alignSelf: 'stretch', marginBottom: '16px' }}>
          <span style={{ textAlign: 'center', color: 'rgba(255, 255, 255, 0.64)', marginBottom: '16px' }}>
            {recovery_observer.send_couner > 0 && !recovery_observer.loading ? (
              <>Сообщение с инструкциями отправлено на E-mail: {recovery_observer.email} </>
            ) : (
              <>Введите ID учетной записи Greenway и мы пришлем ссылку для сброса пароля на связанный E-mail</>
            )}
          </span>
          <StyledOutlinedInput
            autoComplete="recovery section enter username"
            placeholder="GREENWAY ID"
            style={{
              ...(values.username ? { background: /^\d+$/.test(values.username) ? '#C2E3FC' : '#ffdddd' } : {}),
              marginBottom: '16px',
            }}
            value={values.username}
            onChange={handleChange('username')}
          />
        </Stack>
      ) : (
        <Stack spacing={2} sx={{ alignSelf: 'stretch' }}>
          <Button
            fullWidth
            component={'div'}
            size="large"
            variant="contained"
            onClick={() => recovery_observer.recoverByEmail()}
          >
            Восстановить по E-mail
          </Button>
          <Button
            fullWidth
            component={'div'}
            size="large"
            sx={{ color: 'white' }}
            variant="outlined"
            onClick={() => recovery_observer.recoverById()}
          >
            Восстановить по ID
          </Button>
        </Stack>
      )}
      {recovery_observer.recover_by === '' ? (
        ''
      ) : (
        <Stack spacing={2} sx={{ alignSelf: 'stretch' }}>
          <Button
            fullWidth
            component={'div'}
            disabled={recovery_observer.send_couner > 0 || recovery_observer.loading}
            size="large"
            variant="contained"
            onClick={() => recovery_observer.send(values)}
          >
            Получить ссылку
          </Button>
          <Button
            fullWidth
            component={'div'}
            disabled={recovery_observer.send_couner > 1 || recovery_observer.loading}
            size="large"
            sx={{ color: 'white' }}
            variant="outlined"
            onClick={() => recovery_observer.send(values)}
          >
            Отправить ссылку еще раз
          </Button>
        </Stack>
      )}
    </Layout>
  );
});

class PasswordRecoveryStateObserver {
  recover_by: 'username' | 'email' | '';
  send_couner: number;
  email: string;
  loading: boolean;
  constructor() {
    makeObservable(this, {
      recover_by: observable,
      send_couner: observable,
      email: observable,
      loading: observable,
      recoverById: action,
      recoverByEmail: action,
      reset: action,
      send: action,
    });
    this.recover_by = '';
    this.send_couner = 0;
    this.email = '';
    this.loading = false;
  }
  reset() {
    this.recover_by = '';
    this.send_couner = 0;
  }
  send(email_and_id: { email: string; username: string }) {
    if (this.recover_by === '') {
      return;
    }

    const data = { [this.recover_by]: email_and_id[this.recover_by] };

    console.log(data);
    this.loading = true;
    observable_api_store.api.authorization
      .authorizationResetPasswordUpdate(data)
      .then((res) => {
        if (res.status === 200) {
          this.email = res.data.email;
          this.loading = false;
        }
      })
      .catch((res) => {
        console.log(res.response.data);
        this.send_couner--;
        error_store.setError(`${res.response.data.detail}`);
        this.loading = false;
      });
    this.send_couner++;
  }
  recoverById() {
    this.recover_by = 'username';
  }
  recoverByEmail() {
    this.recover_by = 'email';
  }
}

const recovery_observer = new PasswordRecoveryStateObserver();
