import { HTMLAttributes, ReactNode, VFC } from 'react';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

type Props = HTMLAttributes<HTMLDivElement> & {
  iconSrc: string;
  text: ReactNode | string;
};

const Card: VFC<Props> = ({ iconSrc, text, ...other }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }} {...other}>
      <Icon alt="" src={iconSrc} />
      <Typography component="div" variant="body2">
        {text}
      </Typography>
    </Box>
  );
};

const Icon = styled('img')({
  width: 40,
  marginRight: 8,
});

export default Card;
