import React, { useState } from 'react';
import { VFC } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { observable_api_store } from 'api';
import { Link, useSearchParams } from 'react-router-dom';
import { error_store } from 'stores/error';

import { Layout } from '../components/AuthLayout';

import { StyledOutlinedInput } from 'theme/global.styled';

export const ResetPasswordPage: VFC = () => {
  const [values, setValues] = useState({
    password: '',
    repeat_password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState('');

  const handleChange = (prop: 'password' | 'repeat_password') => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const submitPassword = () => {
    const code = searchParams.get('code');

    if (!code) {
      setState('error');
      error_store.setError('Ссылка недействительна');

      return;
    }

    setState('loading');

    observable_api_store.api.authorization
      .authorizationResetNewPasswordUpdate({ code, ...values })
      .then((res) => {
        if (res.status === 200) {
          setState('success');
        } else {
          error_store.setError('Что-то пошло не так. Попробуйте позднее.');

          setState('error');
        }
      })
      .catch((res) => {
        setState('error');

        if (res.response.status < 500) {
          error_store.setError(`${res.response.data.detail}`);
        } else {
          error_store.setError('Что-то пошло не так. Попробуйте позднее.');
        }
      });
  };

  return (
    <Layout back={{ to: '/auth', text: 'Главный экран' }}>
      {state === 'success' ? (
        <>
          <Stack spacing={4} style={{ alignSelf: 'stretch', display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mb: 0 }} variant="h1">
              Готово!
            </Typography>
            <span>Новый пароль успешно создан</span>
            <Button fullWidth component={Link} size="large" to="/auth" variant="contained">
              Далее
            </Button>
          </Stack>
        </>
      ) : (
        <>
          <Typography sx={{ mb: 4 }} variant="h1">
            Введите новый пароль
          </Typography>
          <form style={{ alignSelf: 'stretch' }}>
            <Stack spacing={2}>
              <StyledOutlinedInput
                autoComplete="registration section enter password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      style={{ color: 'gray' }}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Пароль"
                style={values.password ? { background: values.password.length > 6 ? '#C2E3FC' : '#ffdddd' } : {}}
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
              />

              <StyledOutlinedInput
                autoComplete="registration section repeat password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      style={{ color: 'gray' }}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Повторите пароль"
                style={
                  values.repeat_password
                    ? {
                        background:
                          values.password === values.repeat_password && values.password.length > 6
                            ? '#C2E3FC'
                            : '#ffdddd',
                      }
                    : {}
                }
                type={showPassword ? 'text' : 'password'}
                value={values.repeat_password}
                onChange={handleChange('repeat_password')}
              />

              <Button
                fullWidth
                component="div"
                disabled={state === 'loading'}
                size="large"
                variant="contained"
                onClick={submitPassword}
              >
                Сохранить
              </Button>
            </Stack>
          </form>
        </>
      )}
    </Layout>
  );
};
