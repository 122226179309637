import { observable_api_store } from 'api';

import { Status } from 'api/__generated__';

import { DefaultStore } from './default';

class StatusesStore extends DefaultStore<Status[], typeof observable_api_store.api.status.statusList> {}

export const statusesStore = new StatusesStore({
  fetchFn: (...args) => observable_api_store.api.status.statusList(...args),
  initialValue: [],
});
