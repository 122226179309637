import { ReactElement, useMemo } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { State } from 'common/loading';

export const useStateContent = ({ state, successContent }: { state: State; successContent: ReactElement }) => {
  const content = useMemo(() => {
    switch (state) {
      case State.Idle:
      case State.Loading:
        return (
          <Box sx={{ width: 1, height: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        );
      case State.Success:
        return successContent;
      case State.Error:
        return (
          <Typography color="error" sx={{ p: 2.5 }}>
            Что-то пошло не так, попробуйте позднее useStateContent
          </Typography>
        );
    }
  }, [state]);

  return content;
};
