import { AxiosResponse } from 'axios';
import { action, makeObservable, observable } from 'mobx';

import { State } from 'common/loading';

export class DefaultStore<
  T = any,
  F extends (...args: any) => Promise<AxiosResponse<T>> = () => Promise<AxiosResponse<T>>,
> {
  private fetchFn: F;
  private initialValue: T;
  public state: State = State.Idle;
  public data: T;
  private lastArgs: Parameters<F> | null = null;
  private disableStateChange = false;

  constructor({ fetchFn, initialValue }: { fetchFn: F; initialValue: T }) {
    this.initialValue = initialValue;
    this.fetchFn = fetchFn;
    this.data = initialValue;
    makeObservable(this, {
      data: observable,
      state: observable,
      setState: action,
      setData: action,
    });
  }

  public setState = (state: State) => {
    this.state = state;
  };

  public setData = (data: T) => {
    this.data = data;
  };

  public getData = async (...args: Parameters<F>) => {
    this.lastArgs = args;

    if (!this.disableStateChange) {
      this.setState(State.Loading);
    }

    try {
      const { data } = await this.fetchFn(...args);

      this.setData(data);

      if (!this.disableStateChange) {
        this.setState(State.Success);
      }
    } catch (e) {
      if (!this.disableStateChange) {
        this.setState(State.Error);
      }

      console.error(e);
    }
  };

  public reloadData = async ({ disableStateChange }: { disableStateChange?: boolean } = {}) => {
    if (this.lastArgs) {
      this.disableStateChange = !!disableStateChange;

      await this.getData(...this.lastArgs);

      this.disableStateChange = false;
    }
  };

  public destroy = () => {
    this.setState(State.Idle);
    this.setData(this.initialValue);
  };
}
