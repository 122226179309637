import { Box, Button, Typography, styled } from '@mui/material';
import { notificationsStore } from 'stores/notifications';
import { staticStore } from 'stores/static';

import { STATIC_URL } from 'common/env';

import bgNewStepSrc from 'assets/bg_new_step.svg';

const NewStep = () => {
  return (
    <Box
      sx={{
        background: `url(${bgNewStepSrc}) bottom center no-repeat`,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        p: 2.5,
        pb: 8,
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <StepImage
          alt={notificationsStore.newStep?.name}
          src={`${STATIC_URL}${staticStore.data?.find((s) => s.key === notificationsStore.newStep?.key)?.path}`}
        />
        <Typography sx={{ mb: 0.5 }} variant="h2">
          Поздравляем!
        </Typography>
        <Typography sx={{ mb: 2 }} variant="body1">
          Вы выполнили задание и{' '}
          {notificationsStore.newStep?.type === 'achievement' ? (
            <>получили достижение «{notificationsStore.newStep?.name}»</>
          ) : (
            <>достигли нового уровня «{notificationsStore.newStep?.name}»</>
          )}
        </Typography>
        <Button
          fullWidth
          onClick={() => {
            notificationsStore.showAll();
          }}
        >
          Дальше
        </Button>
      </Box>
    </Box>
  );
};

const StepImage = styled('img')({
  display: 'block',
  height: 80,
  margin: '0 auto 8px',
});

export default NewStep;
