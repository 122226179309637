import { observable_api_store } from 'api';
import { DefaultStore } from 'stores/default';

import { Instruction } from 'api/__generated__';

class InstructionsStore extends DefaultStore<
  Instruction[],
  typeof observable_api_store.api.instruction.instructionList
> {}

export const instructionstore = new InstructionsStore({
  fetchFn: (...args) => observable_api_store.api.instruction.instructionList(...args),
  initialValue: [],
});
