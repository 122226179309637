import { VFC } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { OAUTH_BASE_URL } from 'common/env';

import bgSrc from 'assets/bg_login.svg';
import logoSrc from 'assets/logo.svg';

const Login: VFC = () => {
  return (
    <Box
      sx={{
        p: 2.5,
        height: 1,
        background: `url(${bgSrc}) left bottom no-repeat`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Logo alt="Starlight" src={logoSrc} />
      <Typography sx={{ mb: 4 }} variant="h1">
        Starlight
      </Typography>
      <Button
        fullWidth
        component="a"
        href={`${OAUTH_BASE_URL}s/oauth?response_type=code&client_id=app&redirect_uri=${window.location.origin}/oauth`}
        size="large"
        variant="contained"
      >
        Авторизоваться
      </Button>
    </Box>
  );
};

const Logo = styled('img')({
  height: 100,
  marginBottom: 24,
});

export default Login;
