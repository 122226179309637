import { VFC } from 'react';

import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/auth';
import { staticStore } from 'stores/static';
import { statusesStore } from 'stores/statuses';
import { stepsStore } from 'stores/steps';

import { getLastRank } from 'utils/getLastRank';

import { cardBgColor } from 'common/colors';
import { STATIC_URL } from 'common/env';

import CoinsImgSrs from 'assets/icons/coins.svg';
import PvImgSrs from 'assets/icons/pv.svg';

import { teamStore } from '../../store';

const Cards: VFC = () => {
  const rankSteps = stepsStore.data?.filter((step) => step.type === 'rank') || [];

  return (
    <>
      {teamStore.data.map((user) => {
        const lastRank = getLastRank(user.steps?.slice().reverse() || [], rankSteps);

        return (
          <Card key={user.id} sx={{ mb: 2, borderRadius: '0', backgroundColor: cardBgColor }}>
            <CardHeader sx={{ px: 2.5 }} title={`${user.first_name} ${user.last_name}`} />
            <CardContent sx={{ display: 'flex', px: 2.5, justifyContent: 'space-between', pt: 0 }}>
              <PointsItem>
                <PointsIcon alt="Coins" src={CoinsImgSrs} />
                <Typography textAlign="center" variant="body2">
                  {user.coin || '-'}
                </Typography>
              </PointsItem>
              <PointsItem>
                <PointsIcon alt="PV" src={PvImgSrs} />
                <Typography textAlign="center" variant="body2">
                  {user.pv || '-'}
                </Typography>
              </PointsItem>
              <PointsItem>
                <PointsIcon
                  alt="S"
                  src={`${STATIC_URL}${
                    staticStore.data?.find(
                      (s) => s.key === statusesStore.data?.find((s) => s.id === user.status)?.name?.toLowerCase(),
                    )?.path || staticStore.data?.find((s) => s.key === 's1')?.path
                  }`}
                  sx={{
                    opacity: staticStore.data?.find(
                      (s) => s.key === statusesStore.data?.find((s) => s.id === user.status)?.name?.toLowerCase(),
                    )?.path
                      ? 1
                      : 0.3,
                  }}
                />
                <Typography textAlign="center" variant="body2">
                  {user.status != null
                    ? statusesStore.data.find((status) => status.id === user.status)?.name || '-'
                    : '-'}
                </Typography>
              </PointsItem>
              {lastRank ? (
                <PointsItem>
                  <PointsIcon
                    alt="rank"
                    src={`${STATIC_URL}${staticStore.data?.find((s) => s.key === lastRank?.key)?.path}`}
                  />
                  <Typography textAlign="center" variant="body2">
                    {lastRank.name}
                  </Typography>
                </PointsItem>
              ) : (
                <PointsItemInactive>
                  <PointsIcon
                    alt="rank"
                    src={`${STATIC_URL}${staticStore.data?.find((s) => s.key === rankSteps[0]?.key)?.path}`}
                  />
                  <Typography textAlign="center" variant="body2">
                    {rankSteps[0]?.name}
                  </Typography>
                </PointsItemInactive>
              )}
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};

const PointsItem = styled(Box)({
  width: 80,
});

const PointsItemInactive = styled(PointsItem)({
  opacity: 0.3,
});

const PointsIcon = styled('img')({
  display: 'block',
  height: 40,
  margin: '0 auto 8px',
});

export default observer(Cards);
