import { observable_api_store } from 'api';
import { DefaultStore } from 'stores/default';

import { RatingResponse } from 'api/__generated__';

class TeamRaitingStore extends DefaultStore<
  RatingResponse | null,
  typeof observable_api_store.api.teamRating.teamRatingRead
> {}

export const teamRaitingStore = new TeamRaitingStore({
  fetchFn: (...args) => observable_api_store.api.teamRating.teamRatingRead(...args),
  initialValue: null,
});
