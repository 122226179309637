import { makeAutoObservable } from 'mobx';

class ErrorStore {
  error_message = '';

  constructor() {
    makeAutoObservable(this);
  }
  get isError() {
    return this.error_message !== '';
  }
  clearError() {
    this.error_message = '';
  }
  setError(error: string) {
    this.error_message = error;
  }
}

export const error_store = new ErrorStore();
