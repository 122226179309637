import { VFC, useEffect } from 'react';

import { Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { OAUTH_BASE_URL } from 'common/env';
import { TOKEN_KEY } from 'common/localStorage';

const OAuth: VFC = () => {
  const params = useSearchParams();
  const navigate = useNavigate();

  const getToken = async () => {
    const code = params[0].get('code');

    try {
      if (!code) {
        throw new Error('no code');
      }

      const formData = new FormData();

      formData.append('code', code);
      formData.append('redirect_uri', 'localhost');
      formData.append('grant_type', 'authorization_code');
      formData.append('client_id', 'app');

      const {
        data: { access_token },
      } = await axios.post(`${OAUTH_BASE_URL}s/oauth`, formData, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      });

      if (access_token) {
        localStorage.setItem(TOKEN_KEY, access_token);
        window.location.replace('/');

        return;
      }

      throw new Error('no token');
    } catch (e) {
      navigate('/', { replace: true });
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <>
      <Box sx={{ width: 1, height: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    </>
  );
};

export default OAuth;
