import { observable_api_store } from 'api';
import { computed, makeObservable } from 'mobx';
import { DefaultStore } from 'stores/default';
import { staticStore } from 'stores/static';

import { StepGroup } from 'api/__generated__';

import { STATIC_URL } from 'common/env';

class MainStore extends DefaultStore<StepGroup | null, typeof observable_api_store.api.stepGroup.stepGroupRead> {
  constructor(props: {
    fetchFn: typeof observable_api_store.api.stepGroup.stepGroupRead;
    initialValue: StepGroup | null;
  }) {
    super(props);

    makeObservable(this, {
      imagePath: computed,
    });
  }

  public get imagePath() {
    const path = staticStore.data?.find((s) => s.key === this.data?.key)?.path;

    return path && `${STATIC_URL}${path}`;
  }
}

export const mainStore = new MainStore({
  fetchFn: (...args) => observable_api_store.api.stepGroup.stepGroupRead(...args),
  initialValue: null,
});
