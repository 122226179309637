import { VFC, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/auth';
import { staticStore } from 'stores/static';
import { statusesStore } from 'stores/statuses';
import { stepsStore } from 'stores/steps';

import { getLastRank } from 'utils/getLastRank';

import { cardBgColor } from 'common/colors';
import { STATIC_URL } from 'common/env';

import CoinsImgSrs from 'assets/icons/coins.svg';
import PvImgSrs from 'assets/icons/pv.svg';

import { profileStore } from '../../store';
import StepCondition from '../StepCondition';
import { mainStore } from './store';

const Main: VFC = () => {
  useEffect(() => {
    mainStore.getData();

    return () => {
      mainStore.destroy();
    };
  }, []);

  const rankSteps = stepsStore.data?.filter((step) => step.type === 'rank') || [];

  const lastRank = getLastRank(profileStore.stepsReversed, rankSteps);
  const rankImgSrc = lastRank
    ? staticStore.data?.find((s) => s.key === lastRank.key)
    : staticStore.data?.find((s) => s.key === rankSteps[0]?.key);

  return (
    <Box sx={{ mb: 1, backgroundColor: cardBgColor }}>
      <Box sx={{ position: 'relative', paddingTop: '92%' }}>
        {!!mainStore.imagePath && <Planet alt="" src={mainStore.imagePath} />}
        <Typography
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: '85%',
            zIndex: 1,
            textAlign: 'center',
            width: 1,
            px: 2,
          }}
          variant="h2"
        >
          {mainStore?.data?.name}
        </Typography>
      </Box>
      <Box sx={{ p: 2.5, pt: 3 }}>
        {!!rankImgSrc && (
          <RankImage
            alt={lastRank?.name}
            src={`${STATIC_URL}${rankImgSrc.path}`}
            sx={{ opacity: lastRank ? 1 : 0.3 }}
          />
        )}
        <Typography sx={{ mb: 2 }} textAlign="center" variant="h2">
          {authStore.data?.first_name} {authStore.data?.last_name}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2.5 }}>
          <PointsItem>
            <PointsIcon alt="Coins" src={CoinsImgSrs} />
            <Typography textAlign="center" variant="body2">
              {authStore.data?.coin || '-'}
            </Typography>
          </PointsItem>
          <PointsItem>
            <PointsIcon alt="PV" src={PvImgSrs} />
            <Typography textAlign="center" variant="body2">
              {authStore.data?.pv || '-'}
            </Typography>
          </PointsItem>
          <PointsItem>
            <PointsIcon
              alt="S"
              src={`${STATIC_URL}${
                staticStore.data?.find(
                  (s) =>
                    s.key === statusesStore.data?.find((s) => s.id === authStore.data?.status)?.name?.toLowerCase(),
                )?.path || staticStore.data?.find((s) => s.key === 's1')?.path
              }`}
              sx={{
                opacity: staticStore.data?.find(
                  (s) =>
                    s.key === statusesStore.data?.find((s) => s.id === authStore.data?.status)?.name?.toLowerCase(),
                )?.path
                  ? 1
                  : 0.3,
              }}
            />
            <Typography sx={{ textTransform: 'uppercase' }} textAlign="center" variant="body2">
              {authStore.data?.status != null
                ? statusesStore.data?.find((s) => s.id === authStore.data?.status)?.name || '-'
                : '-'}
            </Typography>
          </PointsItem>
        </Box>
        <StepCondition />
      </Box>
    </Box>
  );
};

const Planet = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  display: 'block',
});

const RankImage = styled('img')({
  display: 'block',
  height: 80,
  margin: '0 auto',
});

const PointsItem = styled(Box)({
  margin: '0 20px',
  width: 80,
});

const PointsIcon = styled('img')({
  display: 'block',
  height: 40,
  margin: '0 auto 8px',
});

export default observer(Main);
