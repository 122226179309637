import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  h1: {
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.18px',
    fontWeight: 400,
  },
  h2: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  h3: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  subtitle1: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  body1: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  body2: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  button: {
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 500,
  },
  caption: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  overline: {
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: 400,
  },
};
