import { useEffect } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { observable_api_store } from 'api';
import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/auth';

import Layout from 'components/Layout';
import { AuthRoutes, NoAuthRoutes } from 'components/Router';

function App() {
  useEffect(() => {
    observable_api_store.readTokenFromLocalstorage();
  }, []);

  return (
    <>
      {observable_api_store.token ? (
        <>
          <Layout>
            <AuthRoutes />
          </Layout>
        </>
      ) : (
        <NoAuthRoutes />
      )}
    </>
  );
}

export default observer(App);
