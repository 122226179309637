import { useEffect, useState } from 'react';

import { Backdrop, Box, Icon, IconButton, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import UserAvatar from 'components/UserAvatar';

import { cardBgColor } from 'common/colors';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron_down.svg';

import { teamStore } from './store';

export const CLOSED_SECTION_HEIGHT = 160;

const Team = () => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    teamStore.getData();

    return () => {
      teamStore.destroy();
    };
  }, []);

  return (
    <>
      <Backdrop open={open} onClick={() => setOpen(false)} />
      <Box
        sx={{
          'p': 2,
          'position': 'absolute',
          'display': 'flex',
          'flexDirection': 'column',
          'zIndex': 10,
          'bottom': 0,
          'width': 1,
          'left': 0,
          'height': open ? '100%' : `${CLOSED_SECTION_HEIGHT}px`,
          'maxHeight': '80vh',
          'backgroundColor': cardBgColor,
          'transition': 'height 0.3s ease',
          '&::before': {
            content: '""',
            position: 'absolute',
            visibility: open ? 'visible' : 'hidden',
            opacity: open ? '1' : '0',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(to bottom, #151166, #47adf9)',
            transition: `opacity 0.3s, visibility 0s ${open ? '' : '0.3s'}`,
          },
          '& > *': {
            position: 'relative',
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography fontWeight={500} variant="body1">
            Соберите экипаж
          </Typography>
          <IconButton size="small" sx={{ color: 'text.secondary', p: 0 }} onClick={() => setOpen(!open)}>
            <Icon
              component={ChevronDownIcon}
              sx={{ transform: open ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s' }}
            />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flex: 1, overflow: open ? 'auto' : 'hidden', flexWrap: 'wrap' }}>
          {(open ? teamStore.data : teamStore.data.slice(0, 2)).map((user) => {
            return (
              <Box key={user.id} sx={{ width: { xs: 1 / 2, sm: 1 / 3 }, px: 1, mb: 2 }}>
                <UserAvatar sx={{ margin: '0 auto 8px' }} userId={user.id || 0} />
                <Typography textAlign="center" variant="body2">
                  {user.first_name} {user.last_name}
                </Typography>
                <Typography color="text.secondary" textAlign="center" variant="body2">
                  {user.pv} pv
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default observer(Team);
