import { VFC, useState } from 'react';
import React from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Button, IconButton, InputAdornment, Link, Stack, Typography } from '@mui/material';
import { observable_api_store } from 'api';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { error_store } from 'stores/error';

import { RegistrationForm } from 'api/__generated__';

import { Layout } from '../components/AuthLayout';

import { StyledOutlinedInput } from 'theme/global.styled';

export const RegisterPage: VFC = observer(() => {
  const [values, setValues] = useState<RegistrationForm>({
    password: '',
    email: '',
    repeat_password: '',
    username: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (prop: keyof RegistrationForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Layout back={{ to: '/auth', text: 'Главный экран' }}>
      <Typography sx={{ mb: 4 }} variant="h1">
        Регистрация
      </Typography>
      {auth_logic_observer.registrationSuccess ? (
        <Alert security="success">На ваш email выслана ссылка для подтверждения регистрации</Alert>
      ) : (
        <form style={{ alignSelf: 'stretch' }}>
          <Stack spacing={2}>
            <StyledOutlinedInput
              autoComplete="registration section enter username"
              placeholder="GREENWAY ID"
              style={values.username ? { background: /^\d+$/.test(values.username) ? '#C2E3FC' : '#ffdddd' } : {}}
              value={values.username}
              onChange={handleChange('username')}
            />
            <StyledOutlinedInput
              autoComplete="registration section enter email"
              placeholder="E-mail"
              style={values.email ? { background: /^\S+@\S+\.\S+$/.test(values.email) ? '#C2E3FC' : '#ffdddd' } : {}}
              value={values.email}
              onChange={handleChange('email')}
            />
            <label>
              E-mail, указанный при регистрации на{' '}
              <Link component="a" href="https://new.mygreenway.com/" target={'_blank'}>
                Greenway
              </Link>
            </label>
            <StyledOutlinedInput
              autoComplete="registration section enter password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    style={{ color: 'gray' }}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Пароль"
              style={values.password ? { background: values.password.length > 6 ? '#C2E3FC' : '#ffdddd' } : {}}
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
            />

            <StyledOutlinedInput
              autoComplete="registration section repeat password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    style={{ color: 'gray' }}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Повторите пароль"
              style={
                values.repeat_password
                  ? {
                      background:
                        values.password === values.repeat_password && values.password.length > 6
                          ? '#C2E3FC'
                          : '#ffdddd',
                    }
                  : {}
              }
              type={showPassword ? 'text' : 'password'}
              value={values.repeat_password}
              onChange={handleChange('repeat_password')}
            />

            <Button
              fullWidth
              component="div"
              size="large"
              variant="contained"
              onClick={() => auth_logic_observer.register(values)}
            >
              Зарегистрироваться
            </Button>
          </Stack>
        </form>
      )}
    </Layout>
  );
});

class AuthLogicObserver {
  status: number;
  constructor() {
    makeObservable(this, {
      registrationError: computed,
      registrationSuccess: computed,
      register: action,
      resetError: action,
      status: observable,
    });
    this.status = 0;
  }
  get registrationError() {
    return this.status >= 400;
  }
  get registrationSuccess() {
    return this.status === 200;
  }
  resetError() {
    this.status = 0;
  }
  async register(form: RegistrationForm) {
    const res = await observable_api_store.api.authorization.authorizationRegistrationCreate(form).catch((res) => {
      if (res.response.status < 500) {
        error_store.setError(`${res.response.data.detail}`);
      } else {
        error_store.setError('Что-то пошло не так. Попробуйте позднее.');
      }

      this.status = res.response.status;
    });

    if (!res) {
      return;
    }

    this.status = res.status;
  }
}

const auth_logic_observer = new AuthLogicObserver();
