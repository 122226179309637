import { VFC, useEffect, useState } from 'react';

import { Box, Link, List, ListItem, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import VideoPopup, { IframeProps } from 'components/VideoPopup';

import { instructionstore } from './store';

const Instructions: VFC = () => {
  const [videoIframeProps, setVideoIframeProps] = useState<IframeProps | null>(null);

  useEffect(() => {
    instructionstore.getData();

    return () => {
      instructionstore.destroy();
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          height: 1,
          overflow: 'auto',
          px: 2.5,
          pt: 2.5,
        }}
      >
        <Typography sx={{ mb: 2 }} variant="h1">
          Видеоинструкции
        </Typography>
        <Typography sx={{ mb: 1 }}>
          В этом разделе размещены инструкции, которые вы можете пересмотреть, чтобы лучше подготовиться к предстоящим
          заданиям. Доступ к каждому видео открывается по мере прохождения игры.
        </Typography>
        <List>
          {instructionstore.data.map((instruction) => (
            <ListItem key={instruction.id} sx={{ px: 0 }}>
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setVideoIframeProps({ src: instruction.embed_url, title: instruction.name });
                }}
              >
                {instruction.name}
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
      <VideoPopup iframeProps={videoIframeProps} onClose={() => setVideoIframeProps(null)} />
    </>
  );
};

export default observer(Instructions);
