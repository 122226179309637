import { VFC } from 'react';

import { Box, Typography } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { notificationsStore } from 'stores/notifications';

import Event from './components/Event';

const Cards: VFC = () => {
  return (
    <>
      {/* {!!notificationsStore.unreadNotifications.length && (
        <>
          <Typography sx={{ mb: 1.5 }} variant="body1">
            Новые
          </Typography>
          <Box
            sx={{
              'mb': 4,
              '& > *': {
                mb: 2,
              },
            }}
          >
            {notificationsStore.unreadNotifications.map((event) => (
              <Event key={event.id} event={event} />
            ))}
          </Box>
        </>
      )}
      {!!notificationsStore.readNotifications.length && (
        <>
          {!!notificationsStore.unreadNotifications.length && (
            <Typography sx={{ mb: 1.5 }} variant="body1">
              Прочитанные
            </Typography>
          )}
          <Box
            sx={{
              'mb': 4,
              '& > *': {
                mb: 2,
              },
            }}
          >
            {notificationsStore.readNotifications.map((event) => (
              <Event key={event.id} event={event} />
            ))}
          </Box>
        </>
      )} */}
      {!!notificationsStore.data.length && (
        <>
          <Box
            sx={{
              'mb': 4,
              '& > *': {
                mb: 2,
              },
            }}
          >
            {notificationsStore.data.map((event) => (
              <Event key={event.id} event={event} />
            ))}
          </Box>
        </>
      )}
    </>
  );
};

export default observer(Cards);
