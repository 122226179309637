import { action, computed, makeObservable, observable } from 'mobx';

import { API_BASE_URL } from 'common/env';
import { TOKEN_KEY } from 'common/localStorage';

import { Api } from './__generated__';
import { TokenRefresh } from './__generated__';

class ObservableApiStore {
  token: TokenRefresh | null;
  constructor() {
    makeObservable(this, {
      token: observable,
      authorizationHeader: computed,
      api: computed,
      setToken: action,
      logOut: action,
      readTokenFromLocalstorage: action,
    });
    this.token = null;
  }

  readTokenFromLocalstorage() {
    const localstorage_token_pair = localStorage.getItem(TOKEN_KEY);

    try {
      if (localstorage_token_pair != null) {
        this.token = JSON.parse(localstorage_token_pair);
      } else {
        throw new Error('localstorage token pair is empty');
      }
    } catch (e) {
      this.token = null;
    }
  }

  get authorizationHeader() {
    if (this.token != null) {
      const header = {
        Authorization: `Bearer ${this.token?.access}`,
        // 'X-ACCESS-TOKEN': this.token,
      };

      return header;
    } else {
      return {};
    }
  }
  get api() {
    return new Api({
      baseURL: API_BASE_URL,
      headers: this.authorizationHeader,
    });
  }
  setToken(token: TokenRefresh) {
    this.token = token;
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  }
  logOut() {
    this.token = null;
    localStorage.removeItem(TOKEN_KEY);
  }
}
export const observable_api_store = new ObservableApiStore();
