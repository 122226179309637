import { createTheme } from '@mui/material';

import { typography } from './typography';

export const theme = createTheme({
  typography,
  palette: {
    mode: 'dark',
    primary: {
      light: 'rgba(71, 173, 249, 0.87)',
      main: '#47ADF9',
      contrastText: 'rgba(255 ,255, 255, 0.87)',
      dark: 'rgba(71, 173, 249, 0.87)',
    },
    secondary: {
      main: '#335DB8',
      contrastText: 'rgba(255 ,255, 255, 0.87)',
    },
    text: {
      primary: 'rgba(255 ,255, 255, 0.87)',
      secondary: 'rgba(255 ,255, 255, 0.7)',
      disabled: 'rgba(255 ,255, 255, 0.4)',
    },
    divider: 'rgba(255, 255, 255, 0.16)',
    action: {
      selected: 'rgba(71, 173, 249, 0.24)',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          'background': 'transparent',
          'boxShadow': 'none',
          '&.Mui-expanded': {
            margin: 0,
            zIndex: 1,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: 0,
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: '12px 0',
          },
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h2',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
        },
        sizeLarge: {
          padding: 16,
        },
      },
      defaultProps: {
        size: 'large',
        variant: 'contained',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          'padding': '2px 8px',
          'minHeight': 22,
          'textTransform': 'none',
          'fontSize': 12,
          'color': 'rgba(255 ,255, 255, 0.87)',
          'borderRadius': 4,
          '&.Mui-selected': {
            color: 'rgba(255 ,255, 255, 0.87)',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 22,
          borderRadius: 4,
          background: 'rgba(255, 255, 255, 0.32)',
        },
        flexContainer: {
          position: 'relative',
          zIndex: 1,
        },
        root: {
          minHeight: 26,
          backgroundColor: '#335DB8',
          borderRadius: 4,
          padding: 2,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        color: '#7BD5FD',
      },
    },
  },
});
