import { VFC } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { ROUTES } from 'common/routes';

export const NoAuthRoutes: VFC = () => {
  return (
    <Routes>
      {ROUTES.filter((route) => !route.withAuth).map((route) => (
        <Route key={route.path} element={<route.PageComponent />} path={route.path} />
      ))}
      <Route element={<Navigate replace to="/auth" />} path="*" />
    </Routes>
  );
};

export const AuthRoutes: VFC = () => {
  return (
    <Routes>
      {ROUTES.filter((route) => route.withAuth).map((route) => (
        <Route key={route.path} element={<route.PageComponent />} path={route.path} />
      ))}
      <Route element={<Navigate replace to="/" />} path="*" />
    </Routes>
  );
};
