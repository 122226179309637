import { Box, Button, Modal, Typography } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { promptStore } from 'stores/prompt';

const Prompt = () => {
  return (
    <Modal open={!!promptStore.prompt} onClose={promptStore.prompt?.onCancel}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'calc(100% - 48px)',
          maxWidth: 400,
          background: 'linear-gradient(179.83deg, #151166 0.15%, #0B71BD 133.74%);',
          outline: 'none',
          p: 2,
          color: 'text.primary',
          borderRadius: '4px',
        }}
      >
        <Typography sx={{ mb: 2 }} textAlign="center" variant="h3">
          {promptStore.prompt?.title}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {promptStore.prompt?.buttons.map(({ value, children, sx, onClick, ...props }) => (
            <Button
              key={value}
              sx={{ mx: 1, minWidth: 92, ...sx }}
              onClick={(e) => {
                onClick?.(e);
                promptStore.prompt?.onSelect(value);
              }}
              {...props}
            >
              {children}
            </Button>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default observer(Prompt);
