import { observable_api_store } from 'api';

import { Step } from 'api/__generated__';

import { DefaultStore } from './default';

class StepsStore extends DefaultStore<Step[], typeof observable_api_store.api.step.stepList> {}

export const stepsStore = new StepsStore({
  fetchFn: (...args) => observable_api_store.api.step.stepList(...args),
  initialValue: [],
});
