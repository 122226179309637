import { VFC } from 'react';

import { Typography } from '@mui/material';
import { formatDistance } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/auth';
import { staticStore } from 'stores/static';
import { statusesStore } from 'stores/statuses';
import { stepsStore } from 'stores/steps';

import { EventResponse } from 'api/__generated__';

import { STATIC_URL } from 'common/env';

import readyIconSrc from 'assets/icons/ready.svg';
import registrationIconSrc from 'assets/icons/registration.svg';
import sIconSrc from 'assets/icons/s.svg';

import Card from '../Card';

type Props = {
  event: EventResponse;
};

const Event: VFC<Props> = ({ event }) => {
  const isCurrentUserEvent = String(authStore.data?.id) === String(event.user?.id);

  const timeCaption = (
    <Typography color="text.secondary" component="p" variant="caption">
      {formatDistance(new Date(event.created as string), new Date(), { locale: ruLocale, addSuffix: true })}
    </Typography>
  );

  switch (event.type) {
    case 'crew_not_ready':
      return null;
    case 'crew_ready':
      return (
        <Card
          iconSrc={readyIconSrc}
          text={
            <>
              <p>Поздравляем, экипаж собран и готов к полету!</p>
              {timeCaption}
            </>
          }
        />
      );
    case 'registration':
      return (
        <Card
          iconSrc={registrationIconSrc}
          text={
            <>
              <p>
                {isCurrentUserEvent
                  ? 'Вы зарегистрированы!'
                  : `Поздравляем, ${event.user.first_name} ${event.user.last_name} стал(-а) членом вашего экипажа!`}
              </p>
              {timeCaption}
            </>
          }
        />
      );
    case 'status':
      const status = statusesStore.data.find((status) => status.id === event.status)?.name?.toUpperCase();

      return (
        <Card
          iconSrc={`${STATIC_URL}${staticStore.data?.find((s) => s.key === status?.toLowerCase())?.path}`}
          text={
            <>
              <p>
                {isCurrentUserEvent ? 'Вы' : `Член экипажа ${event.user.first_name} ${event.user.last_name}`} получил
                {isCurrentUserEvent ? 'и' : '(-а)'} статус {status}
              </p>
              {timeCaption}
            </>
          }
        />
      );
    case 'step':
      const step = stepsStore.data.find((step) => step.id === event.step);
      const path = staticStore.data.find((s) => s.key === step?.key)?.path;

      let actionText = <></>;

      switch (step?.type) {
        case 'achievement':
          actionText = <>получил{isCurrentUserEvent ? 'и' : '(-а)'} достижение</>;
          break;
        case 'rank':
          actionText = <>достиг{isCurrentUserEvent ? 'ли' : '(-ла)'} уровня</>;
          break;
        case 'step':
          actionText = <>выполнил{isCurrentUserEvent ? 'и' : '(-а)'} шаг</>;
          break;
      }

      const achievmentText = (
        <>
          {actionText} «{step?.name}»
        </>
      );

      return (
        <Card
          iconSrc={`${STATIC_URL}${path}`}
          text={
            <>
              <p>
                {isCurrentUserEvent ? 'Вы' : `Член экипажа ${event.user.first_name} ${event.user.last_name}`}{' '}
                {achievmentText}
              </p>
              {timeCaption}
            </>
          }
        />
      );
    default:
      const exhaustiveCheck: never = event.type;

      return null;
  }
};

export default observer(Event);
