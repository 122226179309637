import { VFC } from 'react';

import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/auth';

import Achievements from './components/Achievements';
import Main from './components/Main';
import Team, { CLOSED_SECTION_HEIGHT } from './components/Team';
import { useStateContent } from 'hooks/useStateContent';

const Profile: VFC = () => {
  const content = useStateContent({
    state: authStore.state,
    successContent: (
      <Box
        sx={{
          position: 'relative',
          paddingBottom: `${CLOSED_SECTION_HEIGHT}px`,
        }}
      >
        <Main />
        <Achievements />
        <Team />
      </Box>
    ),
  });

  return content;
};

export default observer(Profile);
