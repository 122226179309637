import { FC } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { State } from 'common/loading';

export const ChildrenOrLoading: FC<{ wait_states: string[] }> = ({ children, wait_states }) => {
  return (
    <>
      {wait_states.includes(State.Error) ? (
        <Typography color="error" sx={{ p: 2.5 }}>
          Что-то пошло не так, попробуйте позднее Layout
        </Typography>
      ) : wait_states.includes(State.Loading) ? (
        <Box sx={{ width: 1, height: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </>
  );
};
