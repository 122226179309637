import { VFC } from 'react';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Alert, Box, Snackbar } from '@mui/material';
import { styled } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Link as RouterLink } from 'react-router-dom';
import { error_store } from 'stores/error';

import bgSrc from 'assets/bg_login.svg';
import logoSrc from 'assets/logo.svg';

export const Layout: VFC<{ children: React.ReactNode; back?: { to: string; text: string } }> = observer(
  ({ children, back }) => {
    return (
      <Box
        sx={{
          p: 2.5,
          height: 1,
          background: `url(${bgSrc}) left bottom no-repeat`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '500px',
          margin: 'auto',
        }}
      >
        {back ? (
          <RouterLink
            style={{
              alignSelf: 'flex-start',
              textDecoration: 'none',
              color: 'rgba(255, 255, 255, 0.64)',
              display: 'flex',
              alignItems: 'center',
            }}
            to={back.to}
          >
            <ArrowRightAltIcon style={{ transform: 'rotate(180deg) ' }} />

            <span style={{ margin: '0px 8px' }}>{back.text}</span>
          </RouterLink>
        ) : (
          ''
        )}
        <Logo
          alt="Starlight"
          src={logoSrc}
          style={{
            marginTop: 'auto',
          }}
        />

        {children}

        <div style={{ marginTop: 'auto', textAlign: 'center' }}></div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          open={error_store.isError}
          onClose={() => error_store.clearError()}
        >
          <Alert severity="error">{error_store.error_message}</Alert>
        </Snackbar>
      </Box>
    );
  },
);

const Logo = styled('img')({
  height: 100,
  marginBottom: 24,
});
