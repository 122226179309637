import { IframeHTMLAttributes, VFC } from 'react';

import { Box, Icon, IconButton, Modal } from '@mui/material';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

export type IframeProps = IframeHTMLAttributes<HTMLIFrameElement>;

const VideoPopup: VFC<{
  iframeProps: IframeProps | null;
  onClose: () => void;
}> = ({ iframeProps, onClose }) => {
  return (
    <Modal
      open={!!iframeProps}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClose={onClose}
    >
      <>
        <Box sx={{ width: 0.9, maxWidth: 'md' }}>
          <Box sx={{ width: 1, paddingTop: '56.25%', position: 'relative' }}>
            <Box
              component="iframe"
              sx={{ position: 'absolute', top: 0, left: 0, width: 1, height: 1, border: 'none' }}
              {...iframeProps}
            />
          </Box>
        </Box>
        <IconButton
          size="small"
          sx={{ color: 'text.secondary', p: 0, position: 'absolute', top: 10, right: 10 }}
          onClick={onClose}
        >
          <Icon component={CrossIcon} />
        </IconButton>
      </>
    </Modal>
  );
};

export default VideoPopup;
