import { VFC, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import Cards from './components/Cards';
import { useStateContent } from 'hooks/useStateContent';

import { teamStore } from './store';

const Team: VFC = () => {
  useEffect(() => {
    teamStore.getData();

    return () => {
      teamStore.destroy();
    };
  }, []);

  const content = useStateContent({
    state: teamStore.state,
    successContent: (
      <Box sx={{ height: 1, overflow: 'auto' }}>
        <Typography sx={{ p: 2.5, pb: 0, mb: 2 }} variant="h1">
          Экипаж
        </Typography>
        <Cards />
      </Box>
    ),
  });

  return content;
};

export default observer(Team);
