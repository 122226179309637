import { VFC, useMemo, useState } from 'react';

import { Box, Tab, Tabs, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import Bestseller from './components/Bestseller';
import Starcoin from './components/Starcoin';
import Team from './components/Team';

enum TabType {
  Team = 'team',
  Starcoin = 'starcoin',
  Bestseller = 'bestseller',
}

const Raiting: VFC = () => {
  const [tab, setTab] = useState<TabType>(TabType.Team);

  const content = useMemo(() => {
    switch (tab) {
      case TabType.Team:
        return <Team />;
      case TabType.Starcoin:
        return <Starcoin />;
      case TabType.Bestseller:
        return <Bestseller />;
      default:
        const e: never = tab;
    }
  }, [tab]);

  return (
    <Box sx={{ height: 1, py: 2.5, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ px: 2.5 }}>
        <Typography sx={{ mb: 2 }} variant="h1">
          Рейтинг
        </Typography>
        <Tabs sx={{ mb: 2.5 }} value={tab} variant="fullWidth" onChange={(_, tab) => setTab(tab)}>
          <Tab label="Team Value" value={TabType.Team} />
          <Tab label="Starcoin" value={TabType.Starcoin} />
          <Tab label="Bestseller" value={TabType.Bestseller} />
        </Tabs>
      </Box>
      <Box sx={{ flex: 1, overflow: 'auto' }}>{content}</Box>
    </Box>
  );
};

export default observer(Raiting);
