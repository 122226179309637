import { computed, makeObservable } from 'mobx';
import { authStore } from 'stores/auth';

class ProfileStore {
  constructor() {
    makeObservable(this, {
      stepsReversed: computed,
    });
  }

  public get stepsReversed() {
    return [...(authStore.data?.steps || [])].reverse();
  }
}

export const profileStore = new ProfileStore();
