import { VFC } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Icon, Typography } from '@mui/material';

import bgBottom from 'assets/bg_faq_bottom.svg';
import bgTop from 'assets/bg_faq_top.svg';
import { ReactComponent as ChevronDonwIcon } from 'assets/icons/chevron_down.svg';

import { QUESTIONS } from './constants';

const Faq: VFC = () => {
  return (
    <Box
      sx={{
        height: 1,
        overflow: 'auto',
        background: `url(${bgBottom}) left bottom 90px no-repeat, url(${bgTop}) right top -80px no-repeat`,
      }}
    >
      <Box sx={{ px: 2.5, pt: 2.5, pb: 2 }}>
        <Typography variant="h1">Вопросы и ответы</Typography>
      </Box>
      {QUESTIONS.map((question, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<Icon component={ChevronDonwIcon} />} sx={{ px: 2.5 }}>
            <Typography variant="h2">{question.title}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 3 }}>
            <Typography
              component="div"
              sx={{ '& p': { mb: 1 }, '& h3': { mb: 2 }, '& li': { mb: 2.5 } }}
              variant="body1"
            >
              {question.text}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default Faq;
