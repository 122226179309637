import { ComponentType, FC, useEffect, useState } from 'react';

import {
  Box,
  Drawer,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { observable_api_store } from 'api';
import { observer } from 'mobx-react-lite';
import Lightbox from 'react-image-lightbox';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { authStore } from 'stores/auth';
import { ITEMS_PER_PAGE, notificationsStore } from 'stores/notifications';
import { staticStore } from 'stores/static';
import { statusesStore } from 'stores/statuses';
import { stepsStore } from 'stores/steps';

import Prompt from 'components/Prompt';

import { ROUTES } from 'common/routes';

import bgMenuSrc from 'assets/bg_menu.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/menu/logout.svg';
import { ReactComponent as MapIcon } from 'assets/icons/menu/map.svg';
import logoSrc from 'assets/logo.svg';
import mapSrc from 'assets/map.svg';

import { ChildrenOrLoading } from './ChildrenOrLoading';

const MENU_ROUTES = ROUTES.filter((r) => !!r.menu);
const TOP_MENU_ROUTES = MENU_ROUTES.filter((r) => r.menu?.topMenu);
const BOTTOM_MENU_ROUTES = MENU_ROUTES.filter((r) => !r.menu?.topMenu);

const Layout: FC = ({ children }) => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isMapOpen, setIsMapOpen] = useState<boolean>(false);

  useEffect(() => {
    authStore.getData();
    stepsStore.getData();
    staticStore.getData();
    statusesStore.getData();
    notificationsStore.getData({ limit: ITEMS_PER_PAGE, offset: 0 });

    return () => {
      stepsStore.destroy();
      staticStore.destroy();
      statusesStore.destroy();
      notificationsStore.destroy();
      authStore.destroy();
    };
  }, []);

  return (
    <>
      <Box sx={{ height: 1, maxWidth: 'sm', margin: '0 auto' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
          <Box
            sx={{ boxShadow: 1, height: 56, flex: 'none', px: 2.5, display: 'flex', alignItems: 'center', zIndex: 10 }}
          >
            <IconButton size="small" sx={{ color: 'text.primary', p: 0, mr: 2 }} onClick={() => setIsMenuOpen(true)}>
              <Icon component={MenuIcon} />
            </IconButton>
            <Box component={RouterLink} sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }} to="/">
              <Logo alt="logo" src={logoSrc} />
              <Typography component="p" variant="h1">
                Starlight
              </Typography>
            </Box>
          </Box>
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            <ChildrenOrLoading
              wait_states={[stepsStore.state, staticStore.state, statusesStore.state, notificationsStore.state]}
            >
              {children}
            </ChildrenOrLoading>
          </Box>
        </Box>
      </Box>
      <Drawer
        anchor="left"
        open={isMenuOpen}
        sx={{
          '& .MuiPaper-root': {
            width: '80%',
            background: `url(${bgMenuSrc}) left bottom no-repeat,linear-gradient(to bottom, #151166, #47adf9)`,
          },
        }}
        onClose={() => setIsMenuOpen(false)}
      >
        <Box
          component={RouterLink}
          sx={{
            boxShadow: 1,
            height: 56,
            flex: 'none',
            px: 2.5,
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
          }}
          to="/"
          onClick={() => setIsMenuOpen(false)}
        >
          <Logo alt="logo" src={logoSrc} />
          <Typography component="p" variant="h1">
            Starlight
          </Typography>
        </Box>
        <Box sx={{ flex: 1, overflow: 'auto', p: 2.5 }}>
          <MenuList sx={{ p: 0, mb: 8 }}>
            {TOP_MENU_ROUTES.map((route) => (
              <MenuItem
                key={route.path}
                component={RouterLink}
                sx={{ 'px': 0, 'borderBottom': 1, 'borderColor': 'divider', '&:last-child': { border: 'none' } }}
                to={route.path}
                onClick={() => setIsMenuOpen(false)}
              >
                <ListItemIcon sx={{ color: 'primary.main' }}>
                  <Icon component={route.menu?.Icon as ComponentType} />
                </ListItemIcon>
                <ListItemText>{route.menu?.title}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
          <MenuList sx={{ p: 0 }}>
            <MenuItem
              component="a"
              sx={{ px: 0, borderBottom: 1, borderColor: 'divider' }}
              onClick={() => {
                setIsMenuOpen(false);
                setIsMapOpen(true);
              }}
            >
              <ListItemIcon sx={{ color: 'primary.main' }}>
                <Icon component={MapIcon} />
              </ListItemIcon>
              <ListItemText>Маршрут</ListItemText>
            </MenuItem>
            {BOTTOM_MENU_ROUTES.map((route) => (
              <MenuItem
                key={route.path}
                component={RouterLink}
                sx={{ px: 0, borderBottom: 1, borderColor: 'divider' }}
                to={route.path}
                onClick={() => setIsMenuOpen(false)}
              >
                <ListItemIcon sx={{ color: 'primary.main' }}>
                  <Icon component={route.menu?.Icon as ComponentType} />
                </ListItemIcon>
                <ListItemText>{route.menu?.title}</ListItemText>
              </MenuItem>
            ))}
            <MenuItem
              component="a"
              sx={{ px: 0 }}
              onClick={() => {
                observable_api_store.logOut();
                navigate('/');
              }}
            >
              <ListItemIcon sx={{ color: 'primary.main' }}>
                <Icon component={LogoutIcon} />
              </ListItemIcon>
              <ListItemText>Выйти</ListItemText>
            </MenuItem>
          </MenuList>
        </Box>
      </Drawer>
      <Prompt />
      {isMapOpen && <Lightbox mainSrc={mapSrc} onCloseRequest={() => setIsMapOpen(false)} />}
    </>
  );
};

const Logo = styled('img')({
  height: 40,
  marginRight: 8,
});

export default observer(Layout);
