import { ReactNode } from 'react';

import { ButtonProps } from '@mui/material';
import { action, makeObservable, observable } from 'mobx';

type PromptButtonProps<T extends string> = ButtonProps & {
  value: T;
};

export type TPrompt<T extends string = string> = {
  title: string | ReactNode;
  desc?: string;
  cancelText?: string;
  buttons: PromptButtonProps<T>[];
  onSelect: (value: string) => void;
  onCancel: () => void;
};

class Prompt {
  constructor() {
    makeObservable(this, {
      prompt: observable,
      createPrompt: action,
    });
  }

  public prompt: TPrompt | null = null;

  public createPrompt = <T extends string>(prompt: Omit<TPrompt<T>, 'onSelect' | 'onCancel'>): Promise<T> =>
    new Promise((resolve, reject) => {
      this.prompt = {
        ...prompt,
        onSelect: (value) => {
          resolve(value as T);
          this.prompt = null;
        },
        onCancel: () => {
          reject();
          this.prompt = null;
        },
      };
    });
}

export const promptStore = new Prompt();
