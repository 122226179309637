import { VFC, useEffect, useRef, useState } from 'react';

import { Box, Link, Modal, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { staticStore } from 'stores/static';
import { stepsStore } from 'stores/steps';

import { Step } from 'api/__generated__';

import { cardBgColor } from 'common/colors';
import { STATIC_URL } from 'common/env';

import { profileStore } from '../../store';

const Achievements: VFC = () => {
  const ref = useRef<HTMLDivElement>(null);

  const [openedAchievement, setOpenedAchievement] = useState<Step | null>(null);
  const achievementsSteps = stepsStore.data?.filter((step) => step.type === 'achievement') || [];

  const lastDoneIndex =
    achievementsSteps?.length -
    [...(achievementsSteps || [])].reverse().findIndex((a) => !!profileStore.stepsReversed.find((s) => s === a.id));

  useEffect(() => {
    if (lastDoneIndex && ref.current) {
      ref.current.scrollTo({ left: (lastDoneIndex - 1) * 80, behavior: 'smooth' });
    }
  }, [lastDoneIndex]);

  return (
    <>
      <Box sx={{ backgroundColor: cardBgColor, pt: 2, mb: 1 }}>
        <Typography fontWeight={500} sx={{ mb: 2, px: 2 }} variant="body1">
          Достижения
        </Typography>
        <Box ref={ref} sx={{ display: 'flex', flexWrap: 'nowrap', overflow: 'auto', px: 2, pb: 2 }}>
          {achievementsSteps.map((step) => (
            <Link
              key={step.id}
              sx={{ width: 80, flex: 'none', textDecoration: 'none' }}
              onClick={() => setOpenedAchievement(step)}
            >
              <StepImage
                alt={step.name}
                src={`${STATIC_URL}${staticStore.data?.find((s) => s.key === step.key)?.path}`}
                style={{ opacity: !profileStore.stepsReversed.find((s) => s === step.id) ? '0.3' : '1' }}
              />
              <Typography color="text.primary" component="p" textAlign="center" variant="caption">
                {step.name}
              </Typography>
            </Link>
          ))}
        </Box>
      </Box>
      <Modal open={!!openedAchievement} onClose={() => setOpenedAchievement(null)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'calc(100% - 48px)',
            maxWidth: 400,
            background: 'linear-gradient(179.83deg, #151166 0.15%, #0B71BD 133.74%);',
            outline: 'none',
            p: 2,
            color: 'text.primary',
            borderRadius: '4px',
          }}
        >
          <OpenedStepImage
            alt={openedAchievement?.name}
            src={`${STATIC_URL}${staticStore.data?.find((s) => s.key === openedAchievement?.key)?.path}`}
          />
          <Typography gutterBottom textAlign="center" variant="h2">
            {openedAchievement?.name}
          </Typography>
          <Typography
            component="div"
            sx={{
              '& a': { 'textDecoration': 'none', 'color': '#7BD5FD', '&:hover': { textDecoration: 'underline' } },
            }}
            textAlign="center"
            variant="body1"
          >
            <p dangerouslySetInnerHTML={{ __html: openedAchievement?.condition_description || '' }} />
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

const StepImage = styled('img')((p) => {
  return {
    display: 'block',
    height: 40,
    margin: '0 auto 8px',
  };
});

const OpenedStepImage = styled('img')((p) => {
  return {
    display: 'block',
    height: 80,
    margin: '0 auto 16px',
  };
});

export default observer(Achievements);
