import { VFC, useEffect } from 'react';

import { Box, Icon, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { stepsStore } from 'stores/steps';

import { getLastRank } from 'utils/getLastRank';

import { useStateContent } from 'hooks/useStateContent';

import UserAvatar from 'components/UserAvatar';

import { ReactComponent as CoinsIcon } from 'assets/icons/coins.svg';

import { personalRaitingStore } from './store';

const PersonalRaiting: VFC = () => {
  useEffect(() => {
    personalRaitingStore.getData();

    return () => {
      personalRaitingStore.destroy();
    };
  }, []);

  const content = useStateContent({
    state: personalRaitingStore.state,
    successContent: (
      <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, px: 2.5 }}>
          <Typography color="text.secondary" variant="caption">
            Член Экипажа
          </Typography>
          <Icon component={CoinsIcon} sx={{ fontSize: 24 }} />
        </Box>
        <List sx={{ flex: 1, overflow: 'auto', p: 0 }}>
          {personalRaitingStore.data?.rating?.map((user) => (
            <ListItem
              key={user.id}
              disablePadding
              sx={{
                px: 2.5,
                bgcolor: personalRaitingStore.data?.user.id === user.id ? 'action.selected' : '',
              }}
            >
              <ListItemAvatar>
                <UserAvatar userId={user.id || 0} />
              </ListItemAvatar>
              <ListItemText
                primary={`${user.first_name} ${user.last_name}`}
                secondary={
                  getLastRank(
                    user.steps.slice().reverse(),
                    stepsStore.data?.filter((s) => s.type === 'rank'),
                  )?.name || 'Нет ранга'
                }
              />
              <Typography variant="body1">{Math.round(user.coin)}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    ),
  });

  return content;
};

export default observer(PersonalRaiting);
