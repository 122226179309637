import { observable_api_store } from 'api';
import { computed, makeObservable } from 'mobx';
import { DefaultStore } from 'stores/default';

import { User } from 'api/__generated__';

class AuthStore extends DefaultStore<User | null, typeof observable_api_store.api.user.userRead> {
  constructor(props: { fetchFn: typeof observable_api_store.api.user.userRead; initialValue: User | null }) {
    super(props);

    makeObservable(this, {
      stepsReversed: computed,
    });
  }

  public get stepsReversed() {
    return [...(this.data?.steps || [])].reverse();
  }
}

export const authStore = new AuthStore({
  fetchFn: (...args) => observable_api_store.api.user.userRead(...args),
  initialValue: null,
});
