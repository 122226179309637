import { observable_api_store } from 'api';

import { StaticInfo } from 'api/__generated__';

import { DefaultStore } from './default';

// @ts-expect-error
class StaticStore extends DefaultStore<StaticInfo[], typeof observable_api_store.api.staticInfo.staticInfoList> {}

export const staticStore = new StaticStore({
  fetchFn: (...args) => observable_api_store.api.staticInfo.staticInfoList(...args),
  initialValue: [],
});
